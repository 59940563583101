<script setup lang="ts">
import type { Schedule } from '@respell/database';
import parser from 'cron-parser';
import cronstrue from 'cronstrue';
import { format } from 'date-fns';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';
import ScheduleDrawer from '~/components/modals/ScheduleDrawer.vue';

const spellId = useRouteParams('spellId');
const modal = useModal();
const spellStore = useSpellsStore();
const slideover = useSlideover();
const { schedules } = storeToRefs(spellStore);

await useAsyncData(`schedules/${spellId.value}`, () =>
  spellStore.loadSpellSchedules(),
);

const activeSchedule = ref<Schedule | null>(null);

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'state',
    label: 'Status',
  },
  {
    key: 'schedule',
    label: 'Schedule',
  },
  {
    key: 'next',
    label: 'Next run',
  },
  {
    key: 'actions',
  },
];

const rows = computed(() => {
  if (!schedules?.value?.length) return [];
  return schedules?.value.map((schedule: Schedule) => {
    const interval = parser.parseExpression(schedule.schedule);
    return {
      ...schedule,
      class: activeSchedule.value?.id === schedule.id ? 'bg-gray-100' : '',
      next:
        schedule.state === 'active'
          ? format(interval.next().toDate(), 'MMM d, yyy, h:mm a')
          : 'N/A',
    };
  });
});

const actionItems = (row: Schedule) => {
  const baseItems = [
    [
      {
        label: 'Edit schedule',
        icon: 'i-ph-clock-countdown',
        click: () => slideover.open(ScheduleDrawer, { schedule: row }),
      },
    ],
    [
      {
        label: 'Delete schedule',
        icon: 'i-ph-trash-simple',
        slot: 'danger',
        click: () =>
          modal.open(ConfirmModal, {
            type: 'spell schedule',
            action: 'Delete',
            isDangerous: true,
            message: 'This will delete the schedule',
            onConfirm() {
              spellStore.deleteSchedule(row.id);
            },
          }),
      },
    ],
  ];

  if (row.state === 'active') {
    baseItems[1].push({
      label: 'Disable schedule',
      icon: 'i-ph-calendar-x',
      slot: 'danger',
      click: () =>
        modal.open(ConfirmModal, {
          type: 'spell schedule',
          action: 'Disable',
          isDangerous: true,
          message: 'This will disable the schedule and prevent it from running',
          onConfirm() {
            spellStore.disableSchedule(row.id);
          },
        }),
    });
  } else {
    baseItems[0].push({
      label: 'Enable schedule',
      icon: 'i-ph-calendar-check',
      click: () => spellStore.enableSchedule(row.id),
    });
  }

  return baseItems;
};

function handleSelect(row: Schedule) {
  activeSchedule.value = row;
  slideover.open(ScheduleDrawer, {
    schedule: row,
    onClose: () => (activeSchedule.value = null),
  });
}
</script>
<template>
  <NuxtLayout name="spell-tab" :wide="true">
    <!-- HEADER -->
    <span class="flex justify-between w-full">
      <p class="title">Your Scheduled Runs</p>
      <UButton
        label="Add new"
        icon="i-ph-plus"
        @click="() => slideover.open(ScheduleDrawer)"
      />
    </span>
    <div
      class="border-container mt-m p-m pb-l bg-white flex flex-col rounded-2xl gap-4 items-start"
    >
      <UTable
        v-if="rows.length"
        :columns="columns"
        :rows="rows"
        class="w-full"
        :empty-state="null"
        style="scrollbar-width: auto; overflow-x: scroll"
        :ui="{
          wrapper: 'border-0',
          base: 'border-separate border-spacing-y-2 divide-y-0',
          divide: 'divide-y-0',
          th: {
            font: 'font-normal',
            padding: 'py-0',
          },
          td: {
            base: 'border-y first:border-l first:rounded-l-2xl last:border-r last:rounded-r-2xl',
          },
        }"
        @select="handleSelect"
      >
        <template #schedule-data="{ row }">
          {{ cronstrue.toString(row.schedule) }}
        </template>
        <template #state-data="{ row }">
          <UButton
            :label="row.state"
            :disabled="row.state === 'inactive'"
            color="gray"
            variant="solid"
            :ui="{
              font: 'capitalize',
            }"
            class="pointer-events-none"
          >
            <template v-if="row.state === 'active'" #trailing>
              <UIcon name="i-ph-circle-fill" class="w-3 h-3 text-green-500" />
            </template>
          </UButton>
        </template>
        <template #actions-data="{ row }">
          <UDropdown :items="actionItems(row)" @click.stop>
            <UButton
              color="gray"
              variant="ghost"
              icon="i-ph-dots-three-outline-fill"
            />
            <template #danger="{ item }">
              <div class="flex gap-2">
                <UIcon :name="item.icon" class="w-5 h-5 text-red-300" />
                <p class="text-red-500">{{ item.label }}</p>
              </div>
            </template>
          </UDropdown>
        </template>
      </UTable>
      <div v-else class="flex flex-col w-full py-6 gap-2 items-center">
        <UIcon name="i-ph-calendar-slash" class="text-gray-400 text-3xl" />
        <p class="subtitle">You haven't created any schedules yet</p>
        <p class="body dimmed">Create one to get started</p>
        <UButton
          label="Add new"
          icon="i-ph-plus"
          color="white"
          @click="() => slideover.open(ScheduleDrawer)"
        />
      </div>
    </div>
  </NuxtLayout>
</template>
