<script setup lang="ts">
import type { Schedule } from '@respell/database';
import { CronCore } from '@vue-js-cron/core';
import { useChangeCase } from '@vueuse/integrations/useChangeCase';

const props = defineProps<{
  schedule: Schedule;
}>();

const emit = defineEmits(['close']);

const slideover = useSlideover();
const spellStore = useSpellsStore();
const { liveGraph } = storeToRefs(spellStore);

const isLoading = ref(false);
const drawer = ref<HTMLElement | null>(null);

const state = reactiveComputed(() => {
  const liveSchedule = props.schedule
    ? useCloned(props.schedule).cloned.value
    : null;
  return {
    name: liveSchedule?.name ?? '',
    inputs: liveSchedule?.inputs ?? {},
    schedule: liveSchedule?.schedule ?? '* * * * *',
  };
});

const hasEdited = computed(() => {
  return (
    state.name !== props.schedule?.name ||
    !isEqual(state.inputs, props.schedule?.inputs) ||
    state.schedule !== props.schedule?.schedule
  );
});

const isUpdate = computed(() => {
  return !!props.schedule;
});

function close() {
  emit('close');
  slideover.close();
}

async function onSubmit() {
  isLoading.value = true;
  if (isUpdate.value) {
    await spellStore.updateSchedule({ id: props.schedule.id, ...state });
  } else {
    await spellStore.createSchedule({ ...state });
  }
  isLoading.value = false;
  close();
}

onClickOutside(drawer, () => {
  close();
});
</script>
<template>
  <USlideover
    prevent-close
    :overlay="false"
    :ui="{
      base: 'max-h-[85vh] shadow-lg flex flex-col rounded-2xl mr-l mb-auto mt-24',
      width: 'max-w-xl',
    }"
  >
    <UCard
      ref="drawer"
      key="drawer"
      class="h-full w-full overflow-y-scroll"
      :ui="{
        body: {
          base: 'flex flex-col gap-6 justify-start max-h-[69vh] h-[69vh] overflow-y-auto',
          padding: 'sm:py-4',
        },
        divide: 'divide-y-reverse divide-gray-200',
        ring: '',
        rounded: 'rounded-2xl',
        footer: {
          base: 'flex w-full justify-end sticky bottom-0 border-t mt-2 bg-white border-gray-200 z-50 gap-6',
        },
        header: {
          base: 'flex w-full justify-between sticky top-0 bg-white border-b border-gray-200 z-50',
        },
      }"
    >
      <template #header>
        <span class="flex gap-2">
          <UIcon name="i-ph-calendar-plus" class="text-3xl" />
          <p class="title">{{ isUpdate ? state.name : 'Create a schedule' }}</p>
        </span>

        <UButton
          :padded="false"
          color="gray"
          variant="link"
          icon="i-ph-x"
          @click="close"
        />
      </template>

      <UFormGroup
        label="Schedule Name"
        class="w-full"
        size="xl"
        required
        :error="!state.name.length && 'You must enter a name for this schedule'"
      >
        <UInput v-model="state.name" placeholder="Name your schedule" />
      </UFormGroup>

      <SpellFormInput v-model="state.inputs" :graph="liveGraph" size="sm" />

      <UCard
        class="w-full"
        :ui="{
          rounded: 'rounded-2xl',
          divide: 'divide-y-0',
          header: {
            padding: '!pb-0',
          },
          body: {
            base: `flex flex-col gap-3`,
            padding: 'sm:p-6',
          },
        }"
      >
        <template #header>
          <span class="flex justify-start gap-2">
            <span class="bg-primary-200 rounded-full flex p-1.5">
              <UIcon
                name="i-ph-clock-fill"
                class="text-primary-500 text-large"
              />
            </span>
            <p class="subtitle">Schedule</p>
          </span>
        </template>
        <UFormGroup label="Run this spell..." class="w-full">
          <cron-core
            v-slot="{ fields, period, error }"
            v-model="state.schedule"
          >
            <div
              class="grid grid-cols-[3rem,1fr,3rem,1fr] gap-4 justify-items-start items-center"
            >
              <!-- period selection -->
              <p class="body-sm font-bold text-gray-500">
                {{ period.prefix }}
              </p>

              <USelectMenu
                :options="period.items"
                option-attribute="text"
                value-attribute="id"
                color="gray"
                :model-value="period.attrs.modelValue"
                class="w-full h-full"
                selected-icon="i-ph-x-circle-fill"
                @update:model-value="period.events['update:model-value']"
              >
                <template #label>
                  <p class="body-sm dimmed">
                    {{ period.attrs.modelValue }}
                  </p>
                </template>
              </USelectMenu>

              <p v-if="period.suffix" class="body-sm font-bold text-gray-500">
                {{ period.suffix }}
              </p>

              <span
                v-if="['day', 'year'].includes(period.attrs.modelValue)"
                class="col-span-2"
              />

              <!-- cron expression fields -->

              <template
                v-for="f in fields.filter((f) =>
                  ['month', 'year'].includes(period.attrs.modelValue)
                    ? f.id !== 'dayOfWeek'
                    : true,
                )"
                :key="f.id"
              >
                <p v-if="f.prefix" class="body-sm font-bold text-gray-500">
                  {{ f.prefix }}
                </p>

                <USelectMenu
                  :options="f.items"
                  option-attribute="text"
                  value-attribute="value"
                  :model-value="f.attrs.modelValue"
                  multiple
                  color="gray"
                  class="w-full h-full"
                  selected-icon="i-ph-x-circle-fill"
                  @update:model-value="f.events['update:model-value']"
                >
                  <template #label>
                    <p
                      v-if="f.attrs.modelValue?.length"
                      class="truncate body-sm dimmed"
                    >
                      {{
                        f.attrs.modelValue
                          .map((item) => {
                            const fieldItem = f.items.find(
                              (i) => i.value === item,
                            );
                            if (f.id === 'minute') {
                              return fieldItem?.text;
                            } else if (f.id === 'hour') {
                              const hour = parseInt(item, 10);
                              const period = hour >= 12 ? 'PM' : 'AM';
                              const formattedHour = hour % 12 || 12;
                              return `${formattedHour} ${period}`;
                            } else {
                              return fieldItem?.alt;
                            }
                          })
                          .join(', ')
                      }}
                    </p>
                    <p v-else class="body-sm dimmed">
                      every
                      {{ useChangeCase(f.id, 'noCase').value }}
                    </p>
                  </template>
                </USelectMenu>
              </template>
            </div>
          </cron-core>
        </UFormGroup>
      </UCard>
      <template #footer>
        <UButton
          :label="isUpdate ? 'Save changes' : 'Create'"
          size="xl"
          variant="solid"
          color="primary"
          :disabled="!hasEdited || !state.name.length"
          :loading="isLoading"
          @click="onSubmit"
        />
      </template>
    </UCard>
  </USlideover>
</template>
